import React, {useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import { connect } from 'react-redux'
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import 'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import guestReferral from './components/poll/InvitePage'
import Professor from './components/poll/Home'
import Professors from './components/Professors'
import Students from './components/Students'
import Success from './components/poll/success'

import SignIn from './components/auth/SignIn'
import ParticlesBg from 'particles-bg'

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

import Sidebar from './Components1/Sidebar/Sidebar';
import Dashboard from './Pages1/Dashboard/Dashboard';
//import { Switch, Route } from "react-router-dom";
import Users from './Pages1/Users/Users';
import Logout from './Pages1/Logout/Logout';
import Analytics from './Pages1/Analytics/Analytics';
import News from './Pages1/News/News';
//import './App.css'
import View from './Components1/View';
import AddEdit from './Components1/AddEdit';
import DashboardUi from './components/dashboardui/dashboard'

const App = props => {
  const { auth } = props;
  const [darkState] = useState(false);
  const palletType = darkState ? "dark" : "light";
  const darkTheme = createTheme({
    palette: {
      type: palletType,
    }
  });
  //const handleThemeChange = () => {
   // setDarkState(!darkState);
  //};
  
  return (
    <BrowserRouter>
    {auth.uid ? (





<Switch>
<Route exact path='/' component={DashboardUi}/>
<Route exact path='/dashboard' component={DashboardUi}/>
  <Route exact path='/Professors' component={Professors}/>
  <Route exact path='/students' component={Students}/>

</Switch>





) : (

  <div className="App">
  <Switch>
  <Route exact path='/' component={Professor}/>
        <Route path='/invite/:id' component={guestReferral} />
        <Route path='/success' component={Success} />
        <Route path='/login' component={SignIn} />
  </Switch>
  </div>
  )}
     
    </BrowserRouter>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
  }
}



export default connect(mapStateToProps)(App)

//export default App;
