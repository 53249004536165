import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {Helmet} from "react-helmet";
import {useState} from 'react';
import {
    Select,
    InputLabel,
    
    FormControl,
    
  } from "@material-ui/core";

  import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
  import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
  import { firestoreConnect } from 'react-redux-firebase'
  import firebase from '../../config/fbConfig';
  import { withRouter } from "react-router";
  import Card from "@material-ui/core/Card";
import { connect } from 'react-redux'
import toast, { Toaster } from 'react-hot-toast';
import { guestReferral1 } from '../../store/actions/referralActions'
import { useHistory, useParams } from 'react-router-dom'
//import { useFormik } from 'formik';
import { useFormik,Formik,Form, Field } from 'formik';
import Spinner from '../poll/Spinner'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import StarIcon from '@material-ui/icons/Star';

import * as yup from 'yup';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { FormikTextField,FormikSelectField } from 'formik-material-fields';
import { compose } from 'redux'
import Profile  from './profile'
import makeStyles from "@material-ui/core/styles/makeStyles";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const HEIGHT = window.screen.height;
const WIDTH = window.screen.width;

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 700
  },
  media: {
    height: HEIGHT / 3
  },
  profileImage: {
    position: "relative",
    top: "-110px",
    justifyContent: "center",
    width: theme.spacing(HEIGHT / 40),
    height: theme.spacing(HEIGHT / 40),
    border: "5px solid white",
    margin: "auto"
  },
  profileInfoContainer: {
    position: "relative",
    top: "-100px",
    margin: "auto"
  },
  userName: {
    fontWeight: "bold",
    marginBottom: 0
  },
  userTag: {
    marginTop: 0
  },
  contentContainer: {
    position: "relative",
    top: "-90px"
  }
}));
const CustomizedSelectForFormik = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;
  
    return (
      <Select
        name={name}
        value={value}
        onChange={e => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

const validationSchema = yup.object({
    name: yup
    .string('Enter your Fulle Name')
    .required('Full Name is required'),
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    mobile: yup
      .string('Enter your contact number')
      .min(10, 'Contact number should be of minimum 10 digits length')
      .max(10, 'Contact number should be of maximum 10 digits length')
      .required('Contact number is required'),
    
      
      comments: yup
      .string('Enter Comments')
 
      .required('Comments is required'),
 
      
  });
 
const ReferralForm = (props) => {
    const history = useHistory();
    const [verify,setVerify]=useState(false)
    const [poll,setPoll]=useState()
    const paperStyle={padding :20,height:'70vh',width:280, margin:"60px auto"}
    const avatarStyle={backgroundColor:'#6d7f9f'}   //  #3370bd
    const btnstyle={marginTop:'28px ',backgroundColor:'#6d7f9f'}
    const sendOtp = firebase.functions().httpsCallable('studentotp');
    const [buttontext, setButtontext] = useState('VERIFY');
    const {students} = props;
    const [counter, setCounter] = React.useState(59);
    //const [seconds, setSeconds] = React.useState(10);
    //console.log("students",students)
    const resendOtp = () => {
      sendOtp({
          mobile: mobile
          //otp:1552
         
        }).
        then((res) => {
          const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
           console.log("res",res)
        })
        .catch((error) => {
          console.log('error',error)
        })
      /*sendOtp({
          mobile: mobile,
         
        }).
        then((res) => {
           console.log("res",res)
        })
        .catch((error) => {
          console.log('error',error)
        })*/
    //setOpen(true);
  };
     // console.log("www",props);
    const [user1, setUser] = useState({
        otp:"",
        
      });
      const [valid, setInvalid] = useState();
      const { otp,name,mobile,email,comments,professorId} = user1;
 
      const handleChange  = e => {
          console.log(e.target.name);
          setUser({ ...user1, [e.target.name]: e.target.value });
        };
      
      const handleSubmit = () => {
         setButtontext('cheking..');
        const verifyOtp = firebase.functions().httpsCallable('studentverify');
       
        verifyOtp({
            mobile: mobile,
            otp:parseInt(otp)
           
          }).
          then((res) => {
             //setVerify(true)
             
             let body = {
                fullname: name,
                mobilenumber: mobile,
               
                email: email,
                
                comments: comments,
                professorId: id

            };
           props.guestReferral1(body)
      
  
  
      history.push("/success");
 
            console.log("res",body)
             setOpen(false);
             toast.success('Congratulations!!  Successfully Voted!');

          })
          .catch((error) => {
            setButtontext('VERIFY');
            setInvalid(true)
            console.log('error',error)
          })
          //console.log("wwq",filteredData[0].otp);
          //console.log("wwq",otp);
          // your submit logic
      }
 
      // Timer 
 
    //const [counter, setCounter] = React.useState(0);
    React.useEffect(() => {
        const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);
    const data = {
      cover:
        "https://www.shutterstock.com/blog/wp-content/uploads/sites/5/2017/08/facebook-cover.jpg",
         };
    const [userData, setUserData] = React.useState(data);
  const [dataLoading, setDataLoading] = React.useState(true);

    const { id } = useParams()
    const { user } = props;
    //console.log("user",user);
    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
      const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const { width, height } = useWindowSize()
 if(user){
  
    return (
        <div>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Verify Mobile Number</DialogTitle>
        <DialogContent>
          <DialogContentText>
   
              
               
                <Box color="text.secondary">
                  <Typography variant="body2">
                      Enter OTP Sent to your mobile number {mobile?mobile:""}
                  </Typography>  
                </Box>    
              
          </DialogContentText>
        
           
 
                <ValidatorForm
                onSubmit={handleSubmit}>
                 <TextValidator
                    label="Enter 4 Digit OTP"
                    onChange={handleChange}
                    variant="outlined"
                    inputProps={{ maxLength: 4, minLength: 4 }}
                    name="otp"
                    size="small"
                    type="number"
                    fullWidth
                    validators={['required']}
                    errorMessages={['OTP is required']}
                    value={user1.otp}
                 />
                {valid &&
                <p>Invalid Otp</p>
                }
               <Button type='submit' color='primary' variant="contained" style={btnstyle} fullWidth>{buttontext}</Button>
            </ValidatorForm>
                <Box mt={3} ><Typography fontWeight={500} align="center" color='textSecondary'> Resend OTP in <span style={{color:"green",fontWeight:"bold"}}> 00:{counter} </span> </Typography></Box>
              
                  <Typography align="center">  
                    
                  {counter == "0" &&
                     <Button style={{marginLeft:"5px"}} onClick={resendOtp}> Resend OTP </Button>
                    }
                    
                  
                  </Typography> 
            
            
        </DialogContent>
        <DialogActions>
         {/* <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Subscribe
    </Button>*/}
        </DialogActions>
      </Dialog>
     
 {verify ? (
  <div className={classes.paper} style={{textAlign:"center", minHeight:"800px"}}>
          <Confetti
          width={width}
          height={height}
        >
             
          </Confetti>
              
              <Typography component="h1" variant="h5">
              Successfully Submitted
      </Typography>
      </div>
    ):(


    
<div>


 
    

    <div className={classes.paper} style={{textAlign:"center"}}>
   
   <Typography component="h1" variant="h5" style={{color:"#BE2131"}}>
   Vote For Your Loved Faculty. Award Nomination Process

 </Typography>
 <List component="nav"  aria-label="contacts" style={{color:"#303F9E"}}>
      <ListItem>
        <ListItemIcon>
          <StarIcon style={{color:"#303F9E"}}/>
        </ListItemIcon>
        <ListItemText primary="Anyone wishing to nominate a faculty member for award will fill the form and verify your details." />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <StarIcon style={{color:"#303F9E"}}/>
        </ListItemIcon>
        <ListItemText primary="The form is electronically submitted to the Faculty Awards Committee (FAC)." />
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <StarIcon style={{color:"#303F9E"}}/>
        </ListItemIcon>
        <ListItemText primary="The FAC will review and rate the nominations based on the genuine student votes." />
      </ListItem>

      
    </List>
 </div>
    <Formik
        enableReinitialize={true}
        initialValues={{
       
          name:'',
          email:'',
          mobile:'',
          comments:'',
          id:user.mobilenumber,
           
        
        
  
          
         
  
        }}
        validationSchema= {validationSchema}
        onSubmit={(values, {resetForm}) => {
          const mobilenumber = mobile;
            
          const student = students ? students[mobilenumber] : null
          console.log("students1",id);
          //console.log("students2",[students]);
          var query = firebase.firestore().collection("students").where("mobilenumber", '==', mobile).where("professorId", '==', id)
          .get()
          .then(function(querySnapshot) {
             console.log(querySnapshot.docs.length)
             if(querySnapshot.docs.length > 0){
              querySnapshot.forEach(function(doc) {
                  // doc.data() is never undefined for query doc snapshots
                  console.log(doc.id, " => ", doc.data());
                
                    toast.error('You have already voted');
                
                  //setPoll(doc.id);
              });
            }else{
              //const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
             
              //console.log('mounted', timer);
              const sendOtp = firebase.functions().httpsCallable('studentotp');
              sendOtp({
                 mobile: mobile,
                
               }).
               then((res) => {
                  console.log("res",res)
               })
               .catch((error) => {
                 console.log('error',error)
               })
               setOpen(true);
               setCounter(59)
               
            }
          })
          .catch(function(error) {
              console.log("Error getting documents: ", error);
          });
          console.log("poll",poll);
          return;
          if(student){
              toast.error('Mobile number already exist');
              return
          }
          
           // console.log("values",values)
            //props.guestReferral(values);
            //resetForm({ values: ''});
            //return;
          //props.updateLead(values);

          //alert("values:" + JSON.stringify(values));
        }}
        
      >
        <Form style={{margin:"20px"}}>
  
          <FormControl fullWidth>
          <FormikTextField
                  name="id"
                  //label="Full Name"
                 // margin="normal"
                  //fullWidth
                  value={user.professorId}
                  type="hidden"
                />
           <FormikTextField
                name="name"
                label="Student Name"
                margin="normal"
                onChange={handleChange}
                //value={id}
                fullWidth
              />
          </FormControl>
      
                
          <FormikTextField
                name="email"
                label="Email"
                margin="normal"
                onChange={handleChange}
                fullWidth
              />
               <FormikTextField
                name="mobile"
                label="Mobile"
                margin="normal"
                onChange={handleChange}
                fullWidth
              />
                
          
         <FormikTextField
            name="comments"
            label="What do you like most your professor?"
            margin="normal"
            fullWidth
            multiline
            onChange={handleChange}
            rows={10}
          />
          
         <Box m={2} pt={3}></Box>
      <Button color="primary" variant="contained" fullWidth type="submit">
      I Nominate My Professor 
      </Button>
        </Form>
      </Formik>
 
      </div>

    )}
             </div>
     );
 }else{
    return (
    <div className="container center" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
       
      <Spinner></Spinner>
    <p>Loading...</p>
  </div>
    );
 }
 
};

const mapStateToProps = (state, ownProps) => {
   // console.log("state",ownProps.match.params.id);
    const id = ownProps.match.params.id;
    const students = state.firestore.data.students;
    const users = state.firestore.data.professor;
    //console.log("state1",state);
    const user = users ? users[id] : null
    return {
      
      user:user,
      students: students
      //auth: state.firebase.auth,
      //id:id
    }
    
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        guestReferral1: (body) => dispatch(guestReferral1(body))
    }
  }
  


  export default withRouter(compose(
    connect(mapStateToProps,mapDispatchToProps),
    firestoreConnect([{
      collection: 'professor',
      collection: 'students'
    }])
  )(ReferralForm))
  
