import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import PropTypes from "prop-types";

import makeStyles from "@material-ui/core/styles/makeStyles";
const HEIGHT = window.screen.height;
const WIDTH = window.screen.width;

const useStyles = makeStyles((theme) => ({
  root: {
    //minHeight: 700
  },
  media: {
      background:"black",
      //height:"118px",
    height: HEIGHT / 6
  },
  profileImage: {
    position: "relative",
    top: "-110px",
    justifyContent: "center",
    width: theme.spacing(HEIGHT / 40),
    height: theme.spacing(HEIGHT / 40),
    border: "5px solid white",
    margin: "auto"
  },
  profileInfoContainer: {
    position: "relative",
    top: "-100px",
    margin: "auto"
  },
  userName: {
    fontWeight: "bold",
    marginBottom: 0
  },
  userTag: {
    marginTop: 0
  },
  contentContainer: {
    position: "relative",
    //top: "-90px"
  }
}));
export default function Profile(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardMedia className={classes.media} title="Cover" />
      <Avatar src={props.avatar} className={classes.profileImage} />
      <div className={classes.profileInfoContainer}>
        <Typography
          align={"center"}
          className={classes.userName}
          variant="h4"
          gutterBottom
        >
          {props.userName}
        </Typography>
        <Typography
          align={"center"}
          variant="h5"
          gutterBottom
          className={classes.userTag}
        >
          Designation:{props.userTag}
        </Typography>
        <Typography
          align={"center"}
          variant="h6"
          gutterBottom
          className={classes.userTag}
        >
          Department: {props.userDepartment}
        </Typography>
        <Typography
          align={"center"}
          variant="h6"
          gutterBottom
          className={classes.userTag}
        >
          College: {props.userCollege}
        </Typography>
      </div>
      <CardContent className={classes.contentContainer}>
        <Typography variant="body2" color="textSecondary" component="p">
          {props.content}
        </Typography>
      </CardContent>
    </Card>
  );
}

Profile.propTypes = {
  /** The profile cover. */
  cover: PropTypes.string,
  /** User avatar. */
  avatar: PropTypes.string,
  /** Username. */
  userName: PropTypes.string,
  /** User tag in the app. */
  userTag: PropTypes.string,
  userCollege: PropTypes.string,
  userDepartment: PropTypes.string,
  /** Content. */
  content: PropTypes.string
};

Profile.defaultProps = {
  cover:
    "https://media.sproutsocial.com/uploads/2018/04/Facebook-Cover-Photo-Size.png",
  avatar: null,
  userName: "UserName",
  userTag: "UserTag",
  content: null
};
