import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBMask,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBView,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBFormInline,
  MDBAnimation
} from 'mdbreact';
import Typography from '@material-ui/core/Typography';
import logo from '../../assets/img/EdImmigo-Logo-180x54-2.png'
import Professor from './ProfessorForm'
import './ContactFormPage.css';
import Img from '../../assets/img/img.jpeg'
import {Helmet} from "react-helmet";
import Img1 from '../../assets/img/award.jpg'
import toast, { Toaster } from 'react-hot-toast';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import StarIcon from '@material-ui/icons/Star';

const ReferralForm = (props) => {
    //const history = useHistory();


    return (
      <div id='classicformpage'>
           <Helmet>
         <title>EdSquad</title>
    <meta property="og:title" content="Ed & Immigo Faculty Excellence Awards 2021" />
    <meta
      property="og:description"
      content="Hey! This Teachers's Day is going to be really special to me. I was nominated to Ed & ImmiGo Faculty Excellence Awards.

      Your Vote Can Strengthen My Nomination & Can Top The List.
      
      Kindly, please take a minute of time to vote my nomination. Thanks!"
    />
    <meta
      property="og:image"
      content="https://edimmigo.web.app/static/media/Images-1.30be3e9d.jpg"
    />
    <meta name="twitter:site" content="@username-example" />
    <meta
      name="twitter:card"
      content="summary || summary_large_image || player || app"
    />
    <meta name="twitter:creator" content="@username-example" />

         </Helmet>
        <Router>
          <div>
               {/*
            <MDBNavbar
              dark
              expand='md'
              scrolling
              fixed='top'
              style={{ marginTop: '0px' }}
            >
              <MDBContainer>
                <MDBNavbarBrand>
                <a href="/"><img
                    src={logo}
                    
                    alt=''
                    loading='lazy'
                />
                </a>
                </MDBNavbarBrand>
                <MDBNavbarToggler
                  onClick={this.toggleCollapse('navbarCollapse')}
                />
                <MDBCollapse id='navbarCollapse' isOpen={collapseID} navbar>
                <MDBNavbarNav left>
                   <MDBNavItem active>
                      <MDBNavLink to='#!'>Home</MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to='#!'>Link</MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink to='#!'>Profile</MDBNavLink>
                    </MDBNavItem>
                  </MDBNavbarNav>
                  <MDBNavbarNav right>
                    <MDBNavItem>
                      <MDBFormInline waves>
                        <div className='md-form my-0'>
                          <input
                            className='form-control mr-sm-2'
                            type='text'
                            placeholder='Search'
                            aria-label='Search'
                          />
                        </div>
                      </MDBFormInline>
                    </MDBNavItem>
                  </MDBNavbarNav>

                </MDBCollapse>
              </MDBContainer>
            </MDBNavbar>
            {collapseID && overlay} */}
          </div>
        </Router>

        <MDBView>
          <MDBMask className='d-flex justify-content-center align-items-center gradient' />
          <MDBContainer
            style={{ height: '100%', width: '100%', paddingTop: '0rem' }}
            className='mt-5  d-flex justify-content-center align-items-center'
          >
                 <Toaster />
                <Professor></Professor>
           </MDBContainer>
        </MDBView>

       {/* <MDBContainer>
          <MDBRow className='py-5'>
            <MDBCol md='12' className='text-center'>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </MDBCol>
          </MDBRow>
       </MDBContainer>*/}
      </div>
    );
  }


export default ReferralForm;