import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";
import OtpInput from 'react-otp-input';
import Img from '../../assets/img/img.jpeg'
import Img1 from '../../assets/img/award.jpg'
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';
import {
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavItem,
    MDBNavLink,
    MDBNavbarToggler,
    MDBCollapse,
    MDBMask,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBBtn,
    MDBView,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBInput,
    MDBFormInline,
    MDBAnimation
  } from 'mdbreact';
import {
    Select,
    InputLabel,
    
    FormControl,
    
  } from "@material-ui/core";
  import { makeStyles } from '@material-ui/core/styles';
  
  import CircularProgress from '@material-ui/core/CircularProgress';
 // import { firestoreConnect } from 'react-redux-firebase'
 import { firestoreConnect, withFirestore } from "react-redux-firebase";

  import firebase from '../../config/fbConfig';
  
  import 'firebase/firestore';
  import { withRouter } from "react-router";
  import * as yup from 'yup';
import { connect } from 'react-redux'
import toast, { Toaster } from 'react-hot-toast';
import { guestReferral } from '../../store/actions/referralActions'
import { useHistory, useParams } from 'react-router-dom'
//import { useFormik } from 'formik';
import { useFormik,Formik,Form, Field } from 'formik';
import ImageUpload from 'image-upload-react'
//important for getting nice style.
import 'image-upload-react/dist/index.css'
import Divider from '@material-ui/core/Divider';
import { Grid,Paper, Avatar,Link as Nv } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {useState} from 'react';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
 
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import StarIcon from '@material-ui/icons/Star';
import { FormikTextField,FormikSelectField } from 'formik-material-fields';
import { compose } from 'redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {ShareSocial} from 'react-share-social' 

import {
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    WhatsappIcon,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    LinkedinIcon,
    TwitterIcon,
    WorkplaceShareButton
  } from "react-share";

  const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
 
}));
const CustomizedSelectForFormik = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;
  
    return (
      <Select
        name={name}
        value={value}
        onChange={e => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

const validationSchema = yup.object({
    name: yup
    .string('Enter your Fulle Name')
    .required('Full Name is required'),
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    mobile: yup
      .string('Enter your contact number')
      .min(10, 'Contact number should be of minimum 10 digits length')
      .max(10, 'Contact number should be of maximum 10 digits length')
      .required('Contact number is required'),
    
      college: yup
      .string('Enter your College Name')
      
      .required('College Name is required'),
      
      department: yup
      .string('Enter your  department')
      .required('department is required'),
      designation: yup
      .string('Enter your designation')
      .required('designation is required'),
      question1: yup
      .string('Enter question1')
 
      .required('question1 is required'),
      question2: yup
      .string('Enter question2')
 
      .required('question2 is required'),
    
      question3: yup
      .string('Enter question2')
 
      .required('question3 is required'),
 
      
  });
 
const ReferralForm = (props) => {
    const {students} = props;
   
    //const history = useHistory();
    //const { id } = useParams()
    //const {otp1} = props;
    //console.log("user",user);
    const [verify,setVerify]=useState(false)
    const [buttontext, setButtontext] = useState('VERIFY');
    const paperStyle={padding :20,height:'70vh',width:280, margin:"60px auto"}
    const avatarStyle={backgroundColor:'#6d7f9f'}   //  #3370bd
    const btnstyle={marginTop:'28px ',backgroundColor:'#6d7f9f'}

     // console.log("www",props);
    const [user, setUser] = useState({
        otp:"",
        
      });
      const [valid, setInvalid] = useState(false);
      const { otp,name,mobile,email,college,department,designation,question1,question2,question3} = user;
 
      const handleChange  = e => {
          console.log(e.target.name);
          setUser({ ...user, [e.target.name]: e.target.value });
        };
      
      const handleSubmit = () => {
        //console.log("image64",image64);
        setButtontext('Checking...');
        const verifyOtp = firebase.functions().httpsCallable('verify');
        console.log("image64",image64);
        verifyOtp({
            mobile: mobile,
            otp:parseInt(otp)
           
          }).
          then((res) => {
            setOpen(false);
             setVerify(true)
             let body = {
                fullname: name,
                mobilenumber: mobile,
                profileImage: image64,
                email: email,
                college: college,
                designation: designation,
                department: department,
                question1: question1,
                question2: question2,
                question3: question3

            };
            props.guestReferral(body)
             console.log("res",body)
            
          })
          .catch((error) => {
            setButtontext('VERIFY');
            setInvalid(true)
            console.log('error',error)
          })
          //console.log("wwq",filteredData[0].otp);
          //console.log("wwq",otp);
          // your submit logic
      }
 
      // Timer 
 
    const [counter, setCounter] = React.useState(59);
    React.useEffect(() => {
        const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);
    
    const [imageSrc, setImageSrc] = useState()
    const [image64, setImage64] = useState()
    const [image, setImage] = useState(null);
    const [url, setUrl] = useState("");
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState("");
    const storage = firebase.storage();
    const handleImageSelect = (e) => {
        const file = e.target.files[0];
        if (file) {
          const fileType = file["type"];
          const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
          if (validImageTypes.includes(fileType)) {
            setError("");
            setImage(file);
          } else {
            setError("Please select an image to upload");
          }
        }
        console.log(e.target.files[0].size);
        if (e.target.files) {
            const uploadTask = storage.ref(`images/${e.target.files[0].name}`).put(e.target.files[0]);
      
            uploadTask.on(
              "state_changed",
              snapshot => {
                const progress = Math.round(
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgress(progress);
              },
              error => {
                setError(error);
              },
              () => {
                storage
                  .ref("images")
                  .child(e.target.files[0].name)
                  .getDownloadURL()
                  .then(url => {
                    setUrl(url);
                    setImage64(url);
                    setProgress(0);
                    setImageSrc(URL.createObjectURL(e.target.files[0]))
                  });
              }
            );
          } else {
            setError("Error please choose an image to upload");
          }
      
   
    }

    const [open, setOpen] = React.useState(false);
    const sendOtp = firebase.functions().httpsCallable('sample');
    const resendOtp = () => {
        sendOtp({
            mobile: mobile
            //otp:1552
           
          }).
          then((res) => {
             console.log("res",res)
          })
          .catch((error) => {
            console.log('error',error)
          })
        /*sendOtp({
            mobile: mobile,
           
          }).
          then((res) => {
             console.log("res",res)
          })
          .catch((error) => {
            console.log('error',error)
          })*/
      //setOpen(true);
    };
    const style = {
        //background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 3,
        border: 0,
        color: 'black',
        background:"black",
        padding: '0 30px',
       // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      };
    const handleClose = () => {
      setOpen(false);
    };
    const classes = useStyles();
    return (
        <div>
       <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Verify Mobile Number</DialogTitle>
        <DialogContent>
          <DialogContentText>
   
                
               
                <Box color="text.secondary">
                  <Typography variant="body2">
                      Enter OTP Sent to your mobile number {mobile?mobile:""}
                  </Typography>  
                </Box>    
              
          </DialogContentText>
        
           
 
                <ValidatorForm
                onSubmit={handleSubmit}>
                 <TextValidator
                    label="Enter 4 Digit OTP"
                    onChange={handleChange}
                    variant="outlined"
                    inputProps={{ maxLength: 4, minLength: 4 }}
                    name="otp"
                    size="small"
                    type="number"
                    fullWidth
                    validators={['required']}
                    errorMessages={['OTP is required']}
                    value={user.otp}
                 />
                {valid &&
                <Typography style={{color:"red"}}>Invalid Otp</Typography>
                }
               <Button type='submit' color='primary' variant="contained" style={btnstyle} fullWidth>{buttontext}</Button>
            </ValidatorForm>
                <Box mt={3} ><Typography fontWeight={500} align="center" color='textSecondary'> Resend OTP in <span style={{color:"green",fontWeight:"bold"}}> 00:{counter}</span> </Typography></Box>
              
                  <Typography align="center">  
                    {counter == "0" &&
                     <Button style={{marginLeft:"5px"}} onClick={resendOtp}> Resend OTP </Button>
                    }
                    
                  
                  </Typography> 
            
            
        </DialogContent>
        <DialogActions>
         {/* <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Subscribe
    </Button>*/}
        </DialogActions>
      </Dialog>
            {verify ? (
<div>

<MDBRow>
<MDBAnimation
  type='fadeInLeft'
  delay='.3s'
  className='white-text text-center text-md-left col-md-6 mt-xl-5 mb-5'
>

        <div style={{textAlign:"center"}} className="d-lg-none">

</div>
 {/*<h1 className='h1-responsive font-weight-bold'>
    Sign up right now!
  </h1>
  <hr className='hr-light' />
  <h6 className='mb-4'>
    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rem
    repellendus quasi fuga nesciunt dolorum nulla magnam veniam
    sapiente, fugiat! Commodi sequi non animi ea dolor molestiae,
    quisquam iste, maiores. Nulla.
  </h6>
  <MDBBtn outline color='white'>
    Learn More
</MDBBtn>*/}
 <div style={{textAlign:"center"}}>
<Typography component="h1" variant="h5" style={{color:"#B07C45"}}>
Congrats! We have recieved your nomination dear professor.Now, What's Next?


</Typography>


<List component="nav"  aria-label="contacts" style={{color:"white",textAlign:"center"}}>
   <ListItem>
     <ListItemIcon>
       <StarIcon style={{color:"#B07C45"}}/>
     </ListItemIcon>
     <ListItemText primary="Top best nominations will be awarded on Sep 5th, 2021 i.e on the honourable day of Teachers Day." />
   </ListItem>
   <ListItem>
     <ListItemIcon>
       <StarIcon style={{color:"#B07C45"}}/>
     </ListItemIcon>
     <ListItemText primary="Panel will be thoughtfully read the responses to understand your best teacher moment and shortlist the best out of it." />
   </ListItem>

   <ListItem>
     <ListItemIcon>
       <StarIcon style={{color:"#B07C45"}}/>
     </ListItemIcon>
     <ListItemText primary="To top the list of nominations your need to get maximum number of genuine student votes nominating you for the award." />
   </ListItem>

   <ListItem>
     <ListItemIcon>
       <StarIcon style={{color:"#B07C45"}}/>
     </ListItemIcon>
     <ListItemText primary="To get maximum number of Votes. Keep sharing this nominate me link in your social media, Email and WhatsApp to let your students know about your nomination and to nominate you for the award." />
   </ListItem>

   <ListItem>
     <ListItemIcon>
       <StarIcon style={{color:"#B07C45"}}/>
     </ListItemIcon>
     <ListItemText primary="Nominations will be closed by Aug 31st." />
   </ListItem>

   <ListItem>
     <ListItemIcon>
       <StarIcon style={{color:"#B07C45"}}/>
     </ListItemIcon>
     <ListItemText primary="Award felicitation ceremony will be there on September 5th, 2021. Timings and Venue will be shared with you shortly." />
   </ListItem>

   
 </List>
 </div>
  <div className="d-none d-lg-block"><img
      src={Img}
      alt=''
      className='img-fluid'
    />
  </div>   
  
</MDBAnimation>

<MDBCol md='6' xl='6' className='mb-4' style={{}}>
  <MDBAnimation type='fadeInRight' delay='.3s'>
  <List component="nav"  aria-label="contacts" style={{color:"white",textAlign:"center"}}>
   <ListItem>
    
     <ListItemText primary="Now Your Students Can Reward Your Back With Their Votes.
Get maximum number of votes from your students & top the list to get the Most Followed Faculty Award." />
   </ListItem>
   <ListItem>
    
    <ListItemText primary=" Share the link on your Social Media Like Facebook, Instagram, LinkedIn and in WhatsApp Groups and Status to let your students know about your nomination." />
  </ListItem>
   </List>

      <div>  
      
   
  
        <MDBCard id='classic-card' >
          <MDBCardBody className='white-text'>
          <div>
                <Grid item xs={12} style={{display:"flex",justifyContent:"center"}}>
        <Paper className={classes.paper} style={{maxWidth:"340px",background:"black"}} >
           <FacebookShareButton
           style={{margin:"10px"}}
   url={`https://facultyexcellence.edandimmigo.com/invite/${mobile}`}
   quote={"Hey! This Teachers's Day is going to be really special to me. I was nominated to Ed & ImmiGo Faculty Excellence Awards.\n\nYour Vote Can Strengthen My Nomination & Can Top The List.\n\nKindly, please take a minute of time to vote my nomination.\n\nThanks!\n\n"}
   className="Demo__some-network__share-button"
   hashtag={'#Edandimmigo,#AbroadEducationconsultant'}
   >
   <FacebookIcon size={64} round />
   </FacebookShareButton>
   <WhatsappShareButton
   style={{margin:"10px"}}
           url={`https://facultyexcellence.edandimmigo.com/invite/${mobile}`}
           title={"Hey! This Teachers's Day is going to be really special to me. I was nominated to Ed & ImmiGo Faculty Excellence Awards.\n\nYour Vote Can Strengthen My Nomination & Can Top The List.\n\nKindly, please take a minute of time to vote my nomination.\n\nThanks!\n\n"}
           separator=""
           className="Demo__some-network__share-button"
           >
           <WhatsappIcon size={64} round />
           </WhatsappShareButton>
           <LinkedinShareButton style={{margin:"10px"}} url={`https://facultyexcellence.edandimmigo.com/invite/${mobile}`} className="Demo__some-network__share-button">
           <LinkedinIcon size={64} round />
           </LinkedinShareButton>
           <TwitterShareButton
           style={{margin:"10px"}}
           url={`https://facultyexcellence.edandimmigo.com/invite/${mobile}`}
           title={"Hey! This Teachers's Day is going to be really special to me. I was nominated to Ed & ImmiGo Faculty Excellence Awards.\n\nYour Vote Can Strengthen My Nomination & Can Top The List.\n\nKindly, please take a minute of time to vote my nomination.\n\nThanks!\n\n"}
           className="Demo__some-network__share-button"
           hashtags={['Edandimmigo','FacultyForStudents']}
           >
           <TwitterIcon size={64} round />
           </TwitterShareButton>
           <br/>
   
   
   

   <div style={{background:"black"}}>
   <ShareSocial 
       style={style}
       url ={`https://facultyexcellence.edandimmigo.com/invite/${mobile}`}
       socialTypes={[]}
   />
   </div>
   </Paper>
   </Grid>
   </div>
   </MDBCardBody>
   </MDBCard>
   </div>
   
  </MDBAnimation>
</MDBCol>
<div className="d-lg-none"><img
      src={Img}
      alt=''
      className='img-fluid'
    /></div>
</MDBRow>
</div>



                
            
  ) : (
    <MDBRow>
    <MDBAnimation
      type='fadeInLeft'
      delay='.3s'
      className='white-text text-center text-md-left col-md-6 mt-xl-5 mb-5'
    >
      <img
          src={Img1}
          alt=''
          className='img-fluid'
        />
        
            <div style={{textAlign:"center"}}>
    
    <Typography component="h1" variant="h5" style={{color:"#B07C45"}}>
    Faculty Excellence Award Process Steps to Award
    
    
    </Typography>
    <List component="nav"  aria-label="contacts" style={{color:"white"}}>
    <ListItem>
    <ListItemIcon>
    <StarIcon style={{color:"#B07C45"}}/>
    </ListItemIcon>
    <ListItemText primary="Faculty Nominations to be closed by 31st of August." />
    </ListItem>
    <ListItem>
    <ListItemIcon>
    <StarIcon style={{color:"#B07C45"}}/>
    </ListItemIcon>
    <ListItemText primary="Initial Screening by Committee." />
    </ListItem>
    
    <ListItem>
    <ListItemIcon>
    <StarIcon style={{color:"#B07C45"}}/>
    </ListItemIcon>
    <ListItemText primary="Committee members review the responses personally and evaluate the best." />
    </ListItem>
    
    <ListItem>
    <ListItemIcon>
    <StarIcon style={{color:"#B07C45"}}/>
    </ListItemIcon>
    <ListItemText primary="Genuine Students responses are considered as the real votes" />
    </ListItem>
    
    <ListItem>
    <ListItemIcon>
    <StarIcon style={{color:"#B07C45"}}/>
    </ListItemIcon>
    <ListItemText primary="Committee makes the final selection" />
    </ListItem>
    
    <ListItem>
    <ListItemIcon>
    <StarIcon style={{color:"#B07C45"}}/>
    </ListItemIcon>
    <ListItemText primary="All applications and decisions materials preserved for future reference." />
    </ListItem>
    
    
    </List>
    </div>
     {/*<h1 className='h1-responsive font-weight-bold'>
        Sign up right now!
      </h1>
      <hr className='hr-light' />
      <h6 className='mb-4'>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rem
        repellendus quasi fuga nesciunt dolorum nulla magnam veniam
        sapiente, fugiat! Commodi sequi non animi ea dolor molestiae,
        quisquam iste, maiores. Nulla.
      </h6>
      <MDBBtn outline color='white'>
        Learn More
    </MDBBtn>*/}
      <div className="d-none d-lg-block"><img
          src={Img}
          alt=''
          className='img-fluid'
        />
      </div>   
    
    </MDBAnimation>
    
    <MDBCol md='6' xl='6' className='mb-4'>
      <MDBAnimation type='fadeInRight' delay='.3s'>
        <MDBCard id='classic-card'>
          <MDBCardBody className='white-text'>
          <div>
                <Grid item xs={12}>
        <Paper className={classes.paper}>
              <div className={classes.paper} style={{textAlign:"center"}}>
       
      {/*<Typography component="h1" variant="h5">
      Professor Registration
      </Typography>*/}
    </div>
      
          <Formik
              enableReinitialize={true}
              initialValues={{
                
                name:'',
                email:'',
                mobile:'',
                college:'',
                department:'', 
                designation:'',
                question1:'',
                question2:'',
                question3:''
           
        
                
               
        
              }}
              validationSchema= {validationSchema}
              onSubmit={(values, {resetForm}) => {
                const mobilenumber = mobile;
                
                const student = students ? students[mobilenumber] : null
                console.log("students",mobile);
                if(student){
                    toast.error('Mobile number already exist');
                }
                 // console.log("values",values)
                 if(image64){
                 const sendOtp = firebase.functions().httpsCallable('sample');
                 sendOtp({
                    mobile: mobile,
                   
                  }).
                  then((res) => {
                     console.log("res",res)
                  })
                  .catch((error) => {
                    console.log('error',error)
                  })
                  setOpen(true);
                  setCounter(59)
                }else{
                    toast.error('Please upload profile image');
                }
                  //props.guestReferral(values);
                  //resetForm({ values: ''});
                  //return;
                //props.updateLead(values);
      
                //alert("values:" + JSON.stringify(values));
              }}
              
            >
              <Form style={{margin:"10px"}}>
              <div style={{display:"flex",justifyContent:"center",paddingTop:"10px"}}>
              <Chip
        avatar={<Avatar>1</Avatar>}
        label="Faculty Details "
        clickable
        color="primary"
       
      />
      </div>
              
                 <FormikTextField
                      name="name"
                      label="Full Name"
                 
                      //value={id}
                      onChange={handleChange}
                      fullWidth
                    />
             
              
             
                <FormikTextField
                      name="mobile"
                      label="Mobile Number"
                   
                      //value={id}
                      fullWidth
                      onChange={handleChange}
                    />
            
               
                   <FormikTextField
                      name="email"
                      label="Email"
                    
                      fullWidth
                      onChange={handleChange}
                    />
                
                      <FormikTextField
                      name="college"
                      label="College Name"
                   
                      fullWidth
                      onChange={handleChange}
                    />
                    <FormikTextField
                      name="department"
                      label="Department"
                    
                      onChange={handleChange}
                      fullWidth
                    />
                    <FormikTextField
                      name="designation"
                      label="Designation"
                     
                      fullWidth
                      onChange={handleChange}
                    />
              <div class="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"> <Typography class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated">Upload Photo</Typography></div>
        
    
        <ImageUpload
          handleImageSelect={handleImageSelect}
          imageSrc={imageSrc}
          setImageSrc={setImageSrc}
          style={{
            width: 100,
            height: 100,
            background: 'black'
          }}
        />
         <div>
            {progress > 0 ? <progress value={progress} max="100" /> : ""}
            <p style={{ color: "red" }}>{error}</p>
          </div>
          <Divider></Divider>
          <div style={{display:"flex",justifyContent:"center"}}>
          <Chip
        avatar={<Avatar>2</Avatar>}
        label="How Special Is Your Teaching Journey "
        clickable
        color="primary"
        style={{marginBottom:"10px",marginTop:"10px"}}
       
      />

      </div>
      
      <Typography varient="p">
      <Box fontStyle="oblique" m={1}>
      Answer these questions to know the best about yourself.
      </Box>
          </Typography>
      <br/>

          <Typography mt={5}>What is the most influencing moment in your life leading your career towards teaching?</Typography>
               <FormikTextField
                  name="question1"
                  label="Answer"
                 
                  fullWidth
                  multiline
                  onChange={handleChange}
                  rows={10}
                />
                <Typography mt={5} style={{paddingTop:"20px"}}>What is your best loving "Faculty-Student" moment in your life ?
    Describe the best moments/experience with student(s) that really made you feel like the best teacher and made a life time memorable moment to student</Typography>
                <FormikTextField
                  name="question2"
                  label="Answer"
               
                  fullWidth
                  multiline
                  onChange={handleChange}
                  rows={10}
                />
                <Typography mt={5} style={{paddingTop:"20px"}}>India won 7 Medals In Latest Olympics. To whom will you give the right credit? To Medalists or To Coaches behind them? And Why?</Typography>
                <FormikTextField
                  name="question3"
                  label="Answer"
                  margin="normal"
                  fullWidth
                  multiline
                  onChange={handleChange}
                  rows={10}
                />
                  
                
        
                
               <Box m={2} pt={3}></Box>
               
            <div  style={{paddingTop:"-30px"}}>
            <Button color="primary" variant="contained" fullWidth type="submit">
            Submit My Nomination
            </Button>
         <br/>  <br/>  
            </div>
              </Form>
            </Formik>
            </Paper>
            </Grid>
            </div>
          </MDBCardBody>
        </MDBCard>
      </MDBAnimation>
    </MDBCol>
    <div className="d-lg-none"><img
          src={Img}
          alt=''
          className='img-fluid'
        /></div>
    </MDBRow>
    
 )}
     </div>
     );
 }



  
 const mapStateToProps = (state, ownProps) => {
    // console.log("state",ownProps.match.params.id);
     //const id = ownProps.match.params.id;
     const students = state.firestore.data.students;
     //console.log("state1",users);
     //const user = users ? users[id] : null
     return {
        students: students,
       //auth: state.firebase.auth,
       //id:id
     }
     
   }
   
   const mapDispatchToProps = dispatch => {
     return {
         guestReferral: (body) => dispatch(guestReferral(body))
     }
   }
   
 
 
   export default withRouter(compose(
     connect(mapStateToProps,mapDispatchToProps),
     firestoreConnect([{
       collection: 'professor'
     }])
   )(ReferralForm))
   

