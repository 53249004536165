import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions'
import Logout from './Logout'
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import SpeakerGroupIcon from '@material-ui/icons/SpeakerGroup';

const mapDispatchToProps = (dispatch) => {
    return {
      signOut: () => dispatch(signOut())
    }
  }

export const mainListItems = (
  <div>
    <ListItem button component="a" href="/dashboard">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItem>
    {/*<ListItem button component="a" href="/add-prospects" >
      <ListItemIcon>
        <InsertInvitationIcon />
      </ListItemIcon>
       <ListItemText primary="Add Prospects" />
    </ListItem>
    <ListItem button component="a" href="/add-referrals" >
      <ListItemIcon>
        <AddCircleIcon />
      </ListItemIcon>
       <ListItemText primary="Add Referral" />
    </ListItem>
    <ListItem button component="a" href="/prospects-dashboard" >
      <ListItemIcon>
        <GroupWorkIcon />
      </ListItemIcon>
       <ListItemText primary="Prospects History" />
    </ListItem>
    <ListItem button component="a" href="/referral-dashboard" >
      <ListItemIcon>
        <SpeakerGroupIcon />
      </ListItemIcon>
       <ListItemText primary="Referral History" />
    </ListItem>
    
    <ListItem button component="a" href="/my-profile" >
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="My Profile" />
    </ListItem>
    <ListItem button component="a" href="/payment-dashboard">
      <ListItemIcon>
        <ShoppingCartIcon />
      </ListItemIcon>
      <ListItemText primary="Payment History" />
    </ListItem>
*/}
    {/*<ListItem button>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Reports" />
    </ListItem>*/}

  </div>
);


  
  //export default connect(null, mapDispatchToProps)(mainListItems)
