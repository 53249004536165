import React from "react";
import ReactDOM from "react-dom";
import firebase from "firebase";
import "firebase/firestore";
//import "./styles.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Avatar from '@material-ui/core/Avatar';
import ReactTable from 'react-table'
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
////import 'react-table/react-table.css'
//import firebase from './config'
import XLSX from 'xlsx'


var config = {
    apiKey: "AIzaSyDrDywhxql6StZdnysYm1Ot0yukEBU0nDg",
    authDomain: "edsquad.firebaseapp.com",
    databaseURL: "https://edsquad-default-rtdb.firebaseio.com",
    projectId: "edsquad",
    storageBucket: "edsquad.appspot.com",
    messagingSenderId: "586324749766",
    appId: "1:586324749766:web:9af1db62cbbc78f8f8d6c7"
};
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const db = firebase.firestore();

class Professors extends React.Component {
  constructor() {
    super();

    this.state = {
      data: [],
      page: 0,
      users: [],
      rowsPerPage: 10
    }
    this.exportFile = this.exportFile.bind(this)

  }
  componentDidMount() {
    this.query();
    this.getUsers();
  }

  async query(params) {
    const response = await db
      .collection("professor")
      //.orderBy("id")
      .onSnapshot(snapshot => {
        this.setUsers(snapshot);
      });
  }

  setUsers(documentSnapshot) {
    const users = [...this.state.data];
    //.log(users.length);
    documentSnapshot.docChanges().forEach(change => {
      if (change.type === "added") {
        const user = change.doc.data();
        users.push({
          profile:user.profileImage,
          mobilenumber: user.mobilenumber,
          fullname: user.fullname,
          email: user.email,
          designation: user.designation,
          department: user.department,
          college:user.college,
          createdAt: user.createdAt,
          profileImage:user.profileImage,
          question1:user.question1,
          question2:user.question2,
          question3:user.question3

        });
      }
      if (change.type === "modified1") {
        const updatedUser = change.doc.data();
       // console.log("updating", updatedUser);
        var index = users.findIndex(user => user.id === updatedUser.id);
        this.users[index] = updatedUser;
      }
      if (change.type === "removed1") {
        const removedUser = change.doc.data();
       // console.log("removing", removedUser);
        const index = users.findIndex(user => user.id === removedUser.id);
        this.users.splice(index, 1);
      }
    });
    this.setState({
      data: users
    });
    this.setState({
        users
      })
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };
   

  getUsers() {
    let users = []
    firebase.database().ref(`professor/`).once('value', snapshot => {
      snapshot.forEach(snap => {
        users.push(snap.val())
      })
      this.setState({
        users
      })
    })
  }
  exportFile() {
    let users = [["Full Name", "Mobile", "Email","Designation","Department","College","createdAt","profile image","question1","question2","question3"]]
    //console.log("test",this.state.users);
    this.state.users.forEach((user) => {
      let dForm = new Date(user.createdAt.toDate());
      //console.log("dForm",dForm);
      let userArray = [user.fullname, user.mobilenumber, user.email,user.designation,user.department,user.college,dForm,user.profileImage,user.question1,user.question2,user.question3]
      //console.log("array",userArray);
      users.push(userArray)
    })

    const wb = XLSX.utils.book_new()
    const wsAll = XLSX.utils.aoa_to_sheet(users)
    
    XLSX.utils.book_append_sheet(wb, wsAll, "All Users")
		XLSX.writeFile(wb, "export-data.xlsx")
  }


  render() {
      const userColumns = [
      {
        Header: "Name",
        columns: [
          {
            Header: "Full Name",
            id: "fullname",   
            accessor: d => d.fullname
          },
          {
            Header: "Mobile Number",
            id: "mobile",  
            accessor: d => d.mobilenumber
          }
        ]
      },
      {
        Header: "Age",
        columns: [
          {
            Header: "Age",
            id: "age",  
            accessor: d => d.age
          }
        ]
      }
    ]
    const { data, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    let display;
    

    if (data.length) {
      display = (
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell>Profile</TableCell>
              <TableCell>Full Name</TableCell>
                <TableCell>Mobile</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Designation</TableCell>
                <TableCell>Department</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  return (
                    <TableRow key={n.id}>
                      <TableCell component="th" scope="row">
                       
                        <Avatar alt={n.fullname} src= {n.profile} />
                      </TableCell>
                      <TableCell>{n.fullname}</TableCell>
                      <TableCell>{n.mobilenumber}</TableCell>
                      <TableCell>{n.email}</TableCell>
                      <TableCell>{n.designation}</TableCell>
                      <TableCell>{n.department}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
             <Button
              onClick={this.exportFile}
        variant="contained"
        color="primary"
        size="small"
        //className={classes.button}
        startIcon={<CloudUploadIcon />}
      >Download</Button>
          
         
        </Paper>
      );
    } else {
      display = <div>Loading...</div>;
    }

return <div>{display}</div>;
  }
}

export default Professors;