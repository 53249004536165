import React from "react";


import { connect } from 'react-redux'
import { signIn } from '../../store/actions/authActions'
import { withStyles } from "@material-ui/core/styles";
//import { useFormik } from 'formik';
import { Formik,Form } from 'formik';
import * as yup from 'yup';

import { FormikTextField } from 'formik-material-fields';
import {

   
    MDBBtn,


  } from 'mdbreact';

  const MyTextField = withStyles({
    root: {
    "& .MuiInputBase-root.Mui-disabled": {
        //color: "rgba(0, 0, 0,0.0)"
    },
    "& .MuiFormLabel-root.Mui-disabled": {
        //color: "rgba(0, 0, 0,0.0)"
    },
    
    }
    })(FormikTextField);
const validationSchema = yup.object({
  
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
      password: yup
      .string('Enter your password')
      .min(6, 'ERROR: password is too short')
      //.min(10, 'Contact number should be of minimum 10 digits length')
      .required('Contact number is required'),
 
   
    
 
      
  });
 
const LoginForm = (props) => {
    //const history = useHistory();
    const { auth, authError } = props;
  return (
    <div>
  
  <Formik
      enableReinitialize={true}
      initialValues={{
      
        email:'',
        password:'',
      
     
       

      }}
      validationSchema= {validationSchema}
      onSubmit={(values, {resetForm}) => {
     
          console.log("values",values)
          //props.createLead(values);
          props.signIn(values);
         // resetForm({ values: ''});
          //return;
        //props.updateLead(values);
      
        /*toast.success('Successfully Added Prospects', {
            closeButton: false
          });*/
        //alert("values:" + JSON.stringify(values));
      }}
      
    >
      <Form>
   
        <FormikTextField
              name="email"
              label="Email"
              margin="normal"
           
            InputProps={{
               
            }}
              fullWidth
            />
                     <FormikTextField
              name="password"
              label="Password"
              margin="normal"
              type="password"
              InputProps={{
             
            }}
              fullWidth
            />
           


           <p className="font-small white-text d-flex justify-content-end pb-3">
           <a href="/forgot" className="white-text ml-1">
 Forgot Password?
                </a>
              </p>

  
<div className='text-center mt-4 black-text'>

                        <MDBBtn color='indigo' type="submit">LOGIN</MDBBtn>
                        <div className="center red-text">
              { authError ? <p>{authError}</p> : null }
            </div>
              
                        
                        <hr className='hr-light' />
                        </div>
      </Form>
    </Formik>
 </div>
 );
};

//export default withStyles(styles)(LoginForm);
const mapStateToProps = (state) => {
    return{
      authError: state.auth.authError,
      auth: state.firebase.auth
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      signIn: (creds) => dispatch(signIn(creds))
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)

