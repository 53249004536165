
import 'firebase/firestore';
import 'firebase/auth';
import firebase from 'firebase';
import 'firebase/storage'
import 'firebase/analytics'

// Replace this with your own config details
  const config = {
    apiKey: "AIzaSyDrDywhxql6StZdnysYm1Ot0yukEBU0nDg",
    authDomain: "edsquad.firebaseapp.com",
    databaseURL: "https://edsquad-default-rtdb.firebaseio.com",
    projectId: "edsquad",
    storageBucket: "edsquad.appspot.com",
    messagingSenderId: "586324749766",
    appId: "1:586324749766:web:9af1db62cbbc78f8f8d6c7",
    measurementId: "G-FDJ0PEMF25"
  };
firebase.initializeApp(config);
firebase.functions();
firebase.firestore().settings({ timestampsInSnapshots: true });
firebase.database().ref();
firebase.analytics();

export default firebase 



